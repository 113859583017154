import React, { useMemo } from "react";
// import ListadoContext from "../context/ListadoContext";
// import Select from "../../../components/Select";
// import { image_path_server } from "../../../config";
// import { lightFormat, parseJSON } from "date-fns";
import Default from "./columnas/Default";
import FechaC from "./columnas/Fecha";
import SiNo from "./columnas/SiNo";
import Enlace from "./columnas/Enlace";
import Imagen from "./columnas/Imagen";
import Boton from "./columnas/Boton";
import BotonWhatsapp from "../../components/Btnwsp";
import SelectOnSubmit from "./columnas/SelectOnSubmit";
import SelectEnlazable from "../../components/SelectP";
import EnlaceOpcional from "./columnas/EnlaceOpcional";
import InputAreaEditable from "../../components/InputAreaEditable";
import SiNoEditable from "./columnas/SiNoEditable";
import RadioButtonC from "./columnas/RadioButtonC";
import CheckboxC from "./columnas/CheckboxC";
import InputDate from "./columnas/InputDate";
import InputPassword from "./columnas/InputPassword";
import Toggle from "./columnas/Toggle";
import InputText from "../../components/InputText";
import InputFile from "../../components/InputFile";
import Autocompletar from "../../components/Autocompletar";
import AutocompletarConFetch from "../../components/AutocompletarConFetch";
import InputTextEditable from "./columnas/InputTextEditable";
import NoteTaker from "../../components/NoteTaker";
import Html from "./columnas/Html";
import VerArchivo from "../../components/VerArchivo";
import InputOnSubmit from "./columnas/InputOnSubmit";

import { ListadoProvider } from "../../Listado/Listado";

import ConfigListado from "../../Listado/components/ConfigListado";

import { withTools } from "../../helper/withTools";

import VistaContext from "../context/VistaContext";
import InputBuscador from "./columnas/InputBuscador/InputBuscador";
import Mapa from "./columnas/Mapa";
import { ReadOnlyTextArea } from "../../components/EditorTextArea";

const SwitchCampos = React.memo(
  ({
    data,
    cab,
    indiceData,
    indiceConf,
    Context = VistaContext,
    id_elemento,
    id_global,
    qsBody,
  }) => {
    //const { filtroActivo, setDatos } = useContext(ListadoContext);
    const campokey = cab.campo_alias ? cab.campo_alias : cab.id_a;

    const hijos =
      cab.sc_hijos?.length > 0
        ? cab.sc_hijos
            .filter((cab) => cab.componente !== "hidden")
            .sort((a, b) => a.orden - b.orden)
            .map((s, i) => (
              <MemoizedSwitchCampos
                key={s.id_a}
                data={data}
                indiceData={indiceData}
                indiceConf={indiceConf}
                cab={s}
                padre={cab}
                Context={Context}
                id_elemento={s.id_a + "_" + i}
                id_global={id_global}
                qsBody={qsBody}
              />
            ))
        : null;

    if (
      data[`${cab.id_a}_COMPONENTE`] === "grupo_columnas" ||
      cab.componente === "grupo_columnas"
    ) {
      return (
        <Componente
          hijos={hijos}
          indiceConf={indiceConf}
          indiceData={indiceData}
          qsBody={qsBody}
          cab={cab}
          data={data}
          campokey={campokey}
          Context={Context}
          id_elemento={id_elemento}
          id_global={id_global}
        />
      );
    }

    if (
      data[`${cab.id_a}_COMPONENTE`] === "mapa" ||
      cab.componente === "mapa"
    ) {
      return (
        <div
          className="divCampo"
          style={{
            gridColumn: cab.grid_span ?? "1 / -1",
            display:
              data[`${cab.id_a}_COMPONENTE`] === "null"
                ? "none"
                : cab.componente === "null"
                ? "none"
                : "inherit",
            margin: cab.componente !== "listado" ? "auto 0" : "0 -1rem",
          }}
          key={cab.id_a + indiceData}
        >
          <div
            id={"_" + id_elemento}
            className="tarjeta_grid_item_componente_campo_mapa"
          >
            <Componente
              hijos={hijos}
              indiceConf={indiceConf}
              indiceData={indiceData}
              qsBody={qsBody}
              cab={cab}
              data={data}
              campokey={campokey}
              Context={Context}
              id_elemento={id_elemento}
              id_global={id_global}
            />
          </div>
        </div>
      );
    }

    return (
      <div
        className="divCampo"
        style={{
          gridColumn: cab.grid_span ?? "1 / -1",
          display:
            data[`${cab.id_a}_COMPONENTE`] === "null"
              ? "none"
              : cab.componente === "null"
              ? "none"
              : "block",
          margin: cab.componente !== "listado" ? "auto 0" : "0 -0.75rem",
        }}
        key={cab.id_a + cab.id}
      >
        <div
          id={"_" + id_elemento}
          className="tarjeta_grid_item_componente_campo"
        >
          <Componente
            hijos={hijos}
            indiceConf={indiceConf}
            indiceData={indiceData}
            qsBody={qsBody}
            cab={cab}
            data={data}
            campokey={campokey}
            Context={Context}
            id_elemento={id_elemento}
            id_global={id_global}
          />
        </div>
      </div>
    );
  }
);

const MemoizedSwitchCampos = React.memo(withTools(SwitchCampos));
export default withTools(MemoizedSwitchCampos);

const Componente = ({
  data,
  cab,
  hijos,
  Context,
  campokey,
  id_elemento,
  id_global,
  indiceConf,
  indiceData,
  qsBody,
  valor,
  update_id,
}) => {
  const componente = useMemo(() => {
    if (cab.tipo.id === 19) {
      const botonendpoint = `/vistaBoton/` + cab.id_a;
      const funcion = "endpoint";

      return (
        <Boton
          data={Object.assign(data, qsBody)}
          cab={Object.assign({
            ...cab,
            endpoint: botonendpoint,
            boton_funcion_onClick: funcion,
          })}
          key={cab.id}
        />
      );
    }

    if (cab.clave_envio && cab.clave_envio.trim() !== "") {
      // console.log(cab.id_a, cab.clave_envio);
      data[cab.clave_envio] = valor;
    }
    switch (data[`${cab.id_a}_COMPONENTE`] ?? cab.componente) {
      case undefined || "columna_simple":
        return (
          <Default
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            context={Context}
            campokey={campokey}
            id_elemento={id_elemento}
          />
        );
      case "div":
        return (
          <div id={id_elemento} className={cab.className}>
            {hijos?.map((hijo) => hijo)}
          </div>
        );
      case "listado":
        return (
          <div style={{ width: "100%", fontSize: "0.8rem" }}>
            <ListadoProvider
              key={cab.id_a}
              configuracion={cab}
              id={id_global}
              indiceConf={indiceConf}
            >
              <ConfigListado />
            </ListadoProvider>
          </div>
        );
      case "btn_wsp":
        return (
          <div id={id_elemento} className="tarjeta_grid_item_label_item">
            {cab.nombre ? (
              <div className="vista_label vista_label_fuente">
                {cab.nombre}:
              </div>
            ) : (
              <></>
            )}
            <BotonWhatsapp
              key={cab.id_a}
              data={data}
              cab={cab}
              hijos={hijos}
              campokey={campokey}
              indiceData={indiceData}
              id_elemento={id_elemento}
            />
          </div>
        );
      case "select":
        return (
          <SelectEnlazable
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            context={Context}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );

      case "enum":
        return (
          <SelectEnlazable
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            context={Context}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "select_onSubmit":
        return (
          <SelectOnSubmit
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            context={Context}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "fecha":
        return (
          <FechaC
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "html_puro":
        return (
          <Html
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );

      case "si_no":
        return (
          <SiNo
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "si_no_editable":
        return (
          <SiNoEditable
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "toggle":
        return (
          <Toggle
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "imagen":
        return (
          <Imagen
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "ver_archivo":
        return (
          <VerArchivo
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            context={Context}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "boton":
        return (
          <Boton
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            context={Context}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_buscador":
        return (
          <InputBuscador
            // key={cab.id_a}
            data={data}
            cab={cab}
            // hijos={hijos}
            context={Context}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            // context={VistaContext}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "enlace":
        return (
          <Enlace
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            id_elemento={id_elemento}
            qsBody={qsBody}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "enlace_opcional":
        return (
          <EnlaceOpcional
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            id_elemento={id_elemento}
            qsBody={qsBody}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_editable":
        return (
          <InputAreaEditable
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            context={VistaContext}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "textarea":
        return (
          <ReadOnlyTextArea
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            context={VistaContext}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_textarea":
        return (
          <NoteTaker
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            context={VistaContext}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_number_editable":
        return (
          <InputAreaEditable
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            type="number"
            context={VistaContext}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_text_editable":
        return (
          <InputTextEditable
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            Context={Context}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "radio_button":
        return (
          <RadioButtonC
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "mapa":
        return (
          <Mapa
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            id_elemento={id_elemento}
          />
        );
      case "checkbox":
        return (
          <CheckboxC
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_fecha":
        return (
          <InputDate
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            Context={Context}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_onSubmit":
        return (
          <InputOnSubmit
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            id_elemento={id_elemento}
          />
        );
      case "password":
        return (
          <InputPassword
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_text_number":
        return (
          <InputText
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "input_file":
        return (
          <InputFile
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            id_elemento={id_elemento}
          />
        );
      case "autocompletar":
        return (
          <Autocompletar
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            context={VistaContext}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "autocompletar_fetch":
        return (
          <AutocompletarConFetch
            key={cab.id_a}
            data={data}
            cab={cab}
            hijos={hijos}
            campokey={campokey}
            indiceData={indiceData}
            indiceConf={indiceConf}
            context={VistaContext}
            id_elemento={id_elemento}
            valor={data[campokey]}
            updateid={data[cab.update_id_alias]}
          />
        );
      case "grupo_columnas":
        return (
          <>
            {cab.nombre && cab.nombre.trim() !== "" ? (
              <h5
                style={{
                  marginTop: "0.5rem",
                  width: "100%",
                  gridColumn: "1/-1",
                }}
              >
                {cab.nombre}
              </h5>
            ) : (
              <></>
            )}
            <div
              id={cab.id_a}
              className={`${
                data[cab.id_a + "_className"] ?? cab.className
              } grupo_columnas_m_p`}
            >
              {hijos
                ?.filter((cab) => cab.componente !== "hidden")
                .map((hijo) => hijo)}
            </div>
          </>
        );
      case "null":
        return <></>;
      case "botonera":
        return (
          <div
            id={id_elemento}
            style={{ display: "flex", alignItems: "center" }}
            className={cab.className}
          >
            {cab.nombre ? (
              <div className="vista_label" style={{ fontWeight: "bold" }}>
                {cab.nombre}:
              </div>
            ) : (
              <></>
            )}
            {hijos
              ?.filter((cab) => cab.componente !== "hidden")
              .map((hijo) => hijo)}
          </div>
        );
      default:
        return (
          <>
            {" "}
            {hijos
              ?.filter((cab) => cab.componente !== "hidden")
              .map((hijo) => hijo)}
          </>
        );
    }
  }, [
    data,
    cab,
    hijos,
    Context,
    campokey,
    id_elemento,
    id_global,
    indiceConf,
    indiceData,
    qsBody,
  ]);
  return componente;
};
